define("lh4/routes/authenticated/internet-payments/profile/users", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/routes/json-query-param-mixin", "lh4/utils/lbm2"], function (_exports, _authenticatedRouteMixin, _jsonQueryParamMixin, _lbm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _jsonQueryParamMixin.default, {
    tokenData: Ember.inject.service(),
    ajax: Ember.inject.service(),
    media: Ember.inject.service(),
    permissions: Ember.inject.service(),
    can: Ember.inject.service(),
    location: Ember.inject.service(),
    queryParams: {
      offset: {
        refreshModel: true
      },
      limit: {
        refreshModel: true
      },
      searchTerm: {
        refreshModel: true
      },
      statuses: {
        refreshModel: true,
        type: 'json'
      },
      locations: {
        refreshModel: true,
        type: 'json'
      }
    },
    beforeModel: function beforeModel() {
      if (this.can.cannot('manage users in general')) {
        this.transitionTo('authenticated.internet-payments.profile.index');
      }
    },
    model: function model(_ref) {
      var offset = _ref.offset,
          limit = _ref.limit,
          searchTerm = _ref.searchTerm,
          statuses = _ref.statuses,
          locations = _ref.locations;
      var internetPaymentsLocations = this.modelFor('authenticated').locations.filter(function (location) {
        return (0, _lbm.default)(location);
      }).sortBy('name');
      var users = statuses && !statuses.length || locations && !locations.length ? [] : this.store.query('settings/user', {
        offset: offset,
        limit: limit,
        searchTerm: searchTerm,
        statuses: statuses,
        locations: locations || [this.location.model.id]
      }).catch(function () {
        return [];
      });
      return Ember.RSVP.hash({
        internetPaymentsLocations: internetPaymentsLocations,
        users: users
      });
    },
    setupController: function setupController(controller, model, transition) {
      this._super(controller, model, transition);

      var locationId = this.location.model.id;
      controller.setProperties({
        selectedLocationIds: controller.locations ? _toConsumableArray(controller.locations) : [locationId],
        currentDefaultLocation: locationId
      });
    },
    actions: {
      userSaved: function userSaved() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});