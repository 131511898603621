define("lh4/routes/authenticated/epro/invoices/landing-page", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/routes/authenticated/epro/invoices/sso"], function (_exports, _authenticatedRouteMixin, _sso) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _sso.default.extend(_authenticatedRouteMixin.default, {
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    redirect: function redirect() {
      var _czService$locationSe;

      if (!this.can.can('view invoices for echo-pro/invoice', this.location.model)) {
        return this.transitionTo('/');
      }

      var czService = this.permissions.getService('charge-zoom');

      var _ref = (czService === null || czService === void 0 ? void 0 : (_czService$locationSe = czService.locationService) === null || _czService$locationSe === void 0 ? void 0 : _czService$locationSe.configuration) || {},
          firstSSODate = _ref.firstSSODate,
          isRecentlyOnboarded = _ref.isRecentlyOnboarded;

      if (firstSSODate || isRecentlyOnboarded) {
        return this.transitionTo('authenticated.epro.invoices.sso');
      }

      return this.transitionTo('authenticated.epro.invoices.landing-page');
    }
  });

  _exports.default = _default;
});