define("lh4/mirage/routes/launch-control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageBusinessSettings;

  /* eslint require-jsdoc: "off" */
  function setMirageBusinessSettings(serverObject) {
    var server = serverObject;
    server.namespace = '/api/v1';
    var workflowId = '141ce154-a610-4ff9-a44d-2d1c994e6304';
    server.get('/launch-control/workflows/:locationId', function () {
      return {
        workflows: [{
          createdAt: '2024-04-16T19:24:26.622Z',
          updatedAt: '2024-04-16T19:24:26.622Z',
          workflowId: workflowId,
          workflowName: 'launch-control-v1',
          locationId: 1,
          completedAt: null
        }]
      };
    });
    server.get('/launch-control/modules/:locationId/:workflowName', function (schema, _ref) {
      var locationId = _ref.params.locationId;
      return {
        modules: [{
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T14:08:57.687Z',
          moduleId: 'cc3d2762-69c7-40ba-a8b4-9e9f4a31d72a',
          workflowId: workflowId,
          moduleName: 'mod-installation-details-v1',
          completedAt: locationId === '222' ? '2024-04-20T14:08:57.687Z' : null,
          stepData: {
            contacts: {
              contacts: [{
                name: 'Manager user',
                email: 'manager-user@shift4.com',
                phone: '+37062216843',
                isActive: true,
                contactId: '01HVP7ATZZ7DX0GKG4JM09FJKR',
                createUserLogin: true,
                textMessageEnabled: true,
                role: 'Manager'
              }, {
                name: 'Admin user',
                email: 'admin-user@shift4.com',
                phone: '+37062216843',
                isActive: true,
                contactId: '01HVSV0DXVRBV8KMZE93643YPQ',
                createUserLogin: true,
                textMessageEnabled: true,
                role: 'Admin'
              }, {
                name: 'Manager user2',
                email: 'manager-user-2@shift4.com',
                phone: '+37062216843',
                isActive: true,
                contactId: '01HVSV0DXVRBV8KMZE93643HBG',
                createUserLogin: true,
                textMessageEnabled: true,
                role: 'Manager'
              }]
            }
          },
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: '1a4ff362-9c63-4469-9854-864992c7e891',
          workflowId: workflowId,
          moduleName: 'mod-menu-v1',
          completedAt: locationId === '222' ? '2024-04-20T14:08:57.687Z' : null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: 'f449e10c-914f-4c8f-ac69-c9c42ae715e7',
          workflowId: workflowId,
          moduleName: 'mod-restaurant-basics-v1',
          completedAt: locationId === '222' ? '2024-04-20T14:08:57.687Z' : null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: '2396b241-dba4-4d44-944d-7cdc41bb1653',
          workflowId: workflowId,
          moduleName: 'mod-online-ordering-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: '2070d219-e009-469d-9b51-20bf8f2e2c86',
          workflowId: workflowId,
          moduleName: 'mod-integrations-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: '2070d219-e009-469d-9b51-20bf8f2e2c11',
          workflowId: workflowId,
          moduleName: 'mod-integrations-v2',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: 'f76c27e5-4c09-4bda-9d44-04df9db18d6d',
          workflowId: workflowId,
          moduleName: 'mod-gift-cards-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: 'e2718616-1530-473d-8d0c-4f565ec1b102',
          workflowId: workflowId,
          moduleName: 'mod-loyalty-v1',
          completedAt: null,
          stepData: {
            programs: [{
              name: 'Program 1',
              isActive: true,
              locations: ['1'],
              pointName: 'Point 1'
            }],
            discounts: [{
              name: 'Discount 1',
              type: 'amount',
              value: 1099,
              isActive: true,
              pointCost: '3',
              loyaltyProgramName: 'Program 1'
            }],
            promotions: [{
              name: 'Promotion 1',
              type: 'MULTIPLIER',
              value: '2',
              endDate: '2024-05-15T05:00:00.000Z',
              endTime: '2024-05-21T21:00:00.000Z',
              isActive: true,
              startDate: '2024-05-07T05:00:00.000Z',
              startTime: '2024-05-21T13:00:00.000Z',
              isAllowedFriday: true,
              isAllowedMonday: true,
              isAllowedSunday: false,
              isAllowedTuesday: true,
              isRepeatedYearly: false,
              isAllowedSaturday: false,
              isAllowedThursday: true,
              isAllowedWednesday: true,
              loyaltyProgramName: 'Program 1'
            }]
          },
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: '63575666-7db1-49c3-a982-b16df009d569',
          workflowId: workflowId,
          moduleName: 'mod-reservations-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: 'df9d5ef3-c755-4ae9-86d1-ca7b2fe2bfd9',
          workflowId: workflowId,
          moduleName: 'mod-skytab-bi-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: '7e3dc5e9-0f8b-4a2e-93f4-c173b324f25c',
          workflowId: workflowId,
          moduleName: 'mod-website-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: '9a8fdfe7-cc8d-4f02-8995-9ae74b789db9',
          workflowId: workflowId,
          moduleName: 'mod-table-layout-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: '9a8fdfe3-cc4d-4f02-8995-9ae74b789db9',
          workflowId: '564af2b8-4dd8-4b70-a9b1-352c275fa176',
          moduleName: 'mod-advanced-online-ordering-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-06-14T08:21:34.604Z',
          updatedAt: '2024-06-18T12:52:22.712Z',
          moduleId: '197ba11b-b337-4129-aaed-623cf070153e',
          workflowId: 'feb01698-212c-433e-8088-cac0b793a2a2',
          moduleName: 'mod-employees-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-08-09T08:21:34.604Z',
          updatedAt: '2024-08-10T12:52:22.712Z',
          moduleId: '68d840ab-c5fe-4773-bf0c-2e4c78e4b578',
          workflowId: 'feb01698-212c-433e-8088-cac0b793a2a2',
          moduleName: 'mod-discounts-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-08-09T08:21:34.604Z',
          updatedAt: '2024-08-10T12:52:22.712Z',
          moduleId: '68d840ab-c5fe-4773-bf0c-2e4c78e4b579',
          workflowId: 'feb01698-212c-433e-8088-cac0b793a2a2',
          moduleName: 'mod-kiosk-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }]
      };
    });
    server.get('/status-control/:locationId/modules', function (schema, _ref2) {
      var locationId = _ref2.params.locationId;
      return {
        modules: [{
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T14:08:57.687Z',
          moduleId: 'cc3d2762-69c7-40ba-a8b4-9e9f4a31d72a',
          workflowId: workflowId,
          moduleName: 'mod-installation-details-v1',
          completedAt: locationId === '222' ? '2024-04-20T14:08:57.687Z' : null,
          stepData: {
            contacts: {
              contacts: [{
                name: 'Manager user',
                email: 'manager-user@shift4.com',
                phone: '+37062216843',
                isActive: true,
                contactId: '01HVP7ATZZ7DX0GKG4JM09FJKR',
                createUserLogin: true,
                textMessageEnabled: true,
                role: 'Manager'
              }, {
                name: 'Admin user',
                email: 'admin-user@shift4.com',
                phone: '+37062216843',
                isActive: true,
                contactId: '01HVSV0DXVRBV8KMZE93643YPQ',
                createUserLogin: true,
                textMessageEnabled: true,
                role: 'Admin'
              }, {
                name: 'Manager user2',
                email: 'manager-user-2@shift4.com',
                phone: '+37062216843',
                isActive: true,
                contactId: '01HVSV0DXVRBV8KMZE93643HBG',
                createUserLogin: true,
                textMessageEnabled: true,
                role: 'Manager'
              }]
            }
          },
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: '1a4ff362-9c63-4469-9854-864992c7e891',
          workflowId: workflowId,
          moduleName: 'mod-menu-v1',
          completedAt: locationId === '222' ? '2024-04-20T14:08:57.687Z' : null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: 'f449e10c-914f-4c8f-ac69-c9c42ae715e7',
          workflowId: workflowId,
          moduleName: 'mod-restaurant-basics-v1',
          completedAt: locationId === '222' ? '2024-04-20T14:08:57.687Z' : null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: '2396b241-dba4-4d44-944d-7cdc41bb1653',
          workflowId: workflowId,
          moduleName: 'mod-online-ordering-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: '2070d219-e009-469d-9b51-20bf8f2e2c86',
          workflowId: workflowId,
          moduleName: 'mod-integrations-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: '2070d219-e009-469d-9b51-20bf8f2e2c11',
          workflowId: workflowId,
          moduleName: 'mod-integrations-v2',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: 'f76c27e5-4c09-4bda-9d44-04df9db18d6d',
          workflowId: workflowId,
          moduleName: 'mod-gift-cards-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: 'e2718616-1530-473d-8d0c-4f565ec1b102',
          workflowId: workflowId,
          moduleName: 'mod-loyalty-v1',
          completedAt: null,
          stepData: {
            programs: [{
              name: 'Program 1',
              isActive: true,
              locations: ['1'],
              pointName: 'Point 1'
            }],
            discounts: [{
              name: 'Discount 1',
              type: 'amount',
              value: 1099,
              isActive: true,
              pointCost: '3',
              loyaltyProgramName: 'Program 1'
            }],
            promotions: [{
              name: 'Promotion 1',
              type: 'MULTIPLIER',
              value: '2',
              endDate: '2024-05-15T05:00:00.000Z',
              endTime: '2024-05-21T21:00:00.000Z',
              isActive: true,
              startDate: '2024-05-07T05:00:00.000Z',
              startTime: '2024-05-21T13:00:00.000Z',
              isAllowedFriday: true,
              isAllowedMonday: true,
              isAllowedSunday: false,
              isAllowedTuesday: true,
              isRepeatedYearly: false,
              isAllowedSaturday: false,
              isAllowedThursday: true,
              isAllowedWednesday: true,
              loyaltyProgramName: 'Program 1'
            }]
          },
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: '63575666-7db1-49c3-a982-b16df009d569',
          workflowId: workflowId,
          moduleName: 'mod-reservations-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: 'df9d5ef3-c755-4ae9-86d1-ca7b2fe2bfd9',
          workflowId: workflowId,
          moduleName: 'mod-skytab-bi-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: '7e3dc5e9-0f8b-4a2e-93f4-c173b324f25c',
          workflowId: workflowId,
          moduleName: 'mod-website-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: '9a8fdfe7-cc8d-4f02-8995-9ae74b789db9',
          workflowId: workflowId,
          moduleName: 'mod-table-layout-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-04-19T12:31:40.348Z',
          updatedAt: '2024-04-19T12:31:40.348Z',
          moduleId: '9a8fdfe3-cc4d-4f02-8995-9ae74b789db9',
          workflowId: '564af2b8-4dd8-4b70-a9b1-352c275fa176',
          moduleName: 'mod-advanced-online-ordering-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-06-14T08:21:34.604Z',
          updatedAt: '2024-06-18T12:52:22.712Z',
          moduleId: '197ba11b-b337-4129-aaed-623cf070153e',
          workflowId: 'feb01698-212c-433e-8088-cac0b793a2a2',
          moduleName: 'mod-employees-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-08-09T08:21:34.604Z',
          updatedAt: '2024-08-10T12:52:22.712Z',
          moduleId: '68d840ab-c5fe-4773-bf0c-2e4c78e4b578',
          workflowId: 'feb01698-212c-433e-8088-cac0b793a2a2',
          moduleName: 'mod-discounts-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }, {
          createdAt: '2024-08-09T08:21:34.604Z',
          updatedAt: '2024-08-10T12:52:22.712Z',
          moduleId: '68d840ab-c5fe-4773-bf0c-2e4c78e4b579',
          workflowId: 'feb01698-212c-433e-8088-cac0b793a2a2',
          moduleName: 'mod-kiosk-v1',
          completedAt: null,
          stepData: {},
          isSkipped: false,
          status: 'AVAILABLE'
        }]
      };
    });
    server.get('/launch-control/merchant-order-contacts/:locationId', function () {
      return {
        contacts: [{
          contactId: '01HVP7ATZZ7DX0GKG4JM09FJKR',
          email: 'manpreet.singh@shift4.com',
          name: 'Name 1',
          phone: '8888579751',
          textMessageEnabled: true,
          isActive: true
        }, {
          contactId: '01HVP7ATC9D0S6QWQ0WHH86Z5K',
          email: 'abc@shift4.com',
          name: 'Name 2',
          phone: '8888579755',
          textMessageEnabled: true,
          isActive: true,
          createUserLogin: true
        }]
      };
    });
    server.get('/launch-control/shipping-address/:locationId', function () {
      return {
        streetAddress: '2202 N Irving Street',
        city: 'Allentown',
        state: 'PA',
        zip: '18109'
      };
    });
    server.get('/launch-control/go-live-date/:locationId', function () {
      return {
        GoLiveDate: '2024-04-22T12:31:40.348Z'
      };
    });
    server.get('/launch-control/delivery-platforms/:locationId', function () {
      return {
        uberEats: {
          installed: true
        },
        doorDash: {
          installed: true
        }
      };
    });
    server.get('/launch-control/order-tracker/:locationId', function () {
      return {
        shipped: false,
        OrderTrackerTimeline: {
          hardwareSoftwareDeployed: {
            subHeading: []
          },
          installation: {
            isCurrent: true,
            isComplete: false
          }
        }
      };
    });
    server.get('/launch-control/order-tracker-status/:locationId', function () {
      return {
        taskSystemId: '01HXF2NT1YQY9DKJ49YSHATH03',
        orderId: '368112',
        menuProgrammingComplete: false,
        installationScheduled: false,
        installationComplete: false,
        equipmentShipped: false
      };
    });
    server.get('/status-control/:id/module/:moduleName', function (schema, _ref3) {
      var _ref3$params = _ref3.params,
          id = _ref3$params.id,
          moduleName = _ref3$params.moduleName;

      if (moduleName === 'mod-installation-details-v1') {
        return {
          module: [{
            createdAt: '2024-04-19T12:31:40.348Z',
            updatedAt: '2024-04-19T14:08:57.687Z',
            moduleId: 'cc3d2762-69c7-40ba-a8b4-9e9f4a31d72a',
            workflowId: workflowId,
            moduleName: 'mod-installation-details-v1',
            completedAt: null,
            stepData: {
              contacts: {
                contacts: [{
                  name: 'Manager user',
                  email: 'manager-user@shift4.com',
                  phone: '+37062216843',
                  isActive: true,
                  contactId: '01HVP7ATZZ7DX0GKG4JM09FJKR',
                  createUserLogin: true,
                  textMessageEnabled: true,
                  role: 'Manager'
                }, {
                  name: 'Admin user',
                  email: 'admin-user@shift4.com',
                  phone: '+37062216843',
                  isActive: true,
                  contactId: '01HVSV0DXVRBV8KMZE93643YPQ',
                  createUserLogin: true,
                  textMessageEnabled: true,
                  role: 'Admin'
                }, {
                  name: 'Manager user2',
                  email: 'manager-user-2@shift4.com',
                  phone: '+37062216843',
                  isActive: true,
                  contactId: '01HVSV0DXVRBV8KMZE93643HBG',
                  createUserLogin: true,
                  textMessageEnabled: true,
                  role: 'Manager'
                }]
              }
            },
            isSkipped: false,
            status: 'AVAILABLE'
          }]
        };
      }

      return {
        module: [{
          moduleId: '232-232-232-232',
          moduleName: moduleName,
          locationId: id,
          completedAt: null,
          stepData: {}
        }]
      };
    });
  }
});