define("lh4/routes/authenticated/internet-payments/reporting/subscriptions", ["exports", "lh4/enums/products", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/lighthouse-permissions-mixin", "lh4/utils/lbm2"], function (_exports, _products, _authenticatedRouteMixin, _lighthousePermissionsMixin, _lbm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, {
    titleToken: 'Subscriptions',
    tokenData: Ember.inject.service(),
    reportApi: Ember.inject.service('reports/internet-payment-report-api'),
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    queryParams: {
      offset: {
        refreshModel: true
      },
      limit: {
        refreshModel: true
      },
      filterLocationId: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (this.can.cannot('create own subscriptions in general')) {
        this.transitionTo('authenticated.internet-payments.reporting.index');
      }

      this.reportApi.setRequestUrl('internet-payments/reports');
    },
    model: function model(_ref) {
      var _this = this;

      var filterLocationId = _ref.filterLocationId,
          offset = _ref.offset,
          limit = _ref.limit;
      var locations = this.modelFor('authenticated').locations.filter(function (location) {
        return (0, _lbm.default)(location) && _this.can.can('create own subscriptions in general', null, {
          locationId: location.id
        });
      }).sortBy('name');
      var locationId = filterLocationId || this.location.model.id;

      if (this.can.cannot('create own subscriptions in general', null, {
        locationId: locationId
      })) {
        locationId = locations.firstObject.id;
      }

      var reportTypes = this.reportApi.fetchReportList(this.location.model.id).then(function (reports) {
        return reports === null || reports === void 0 ? void 0 : reports[_products.default.INTERNET_PAYMENTS];
      });
      var subscriptions = this.store.query('reports/subscription', {
        offset: offset,
        limit: limit,
        locationId: locationId,
        product: _products.default.INTERNET_PAYMENTS,
        order: 'createdAt',
        sort: 'asc'
      });
      return Ember.RSVP.hash({
        locations: locations,
        selectedlocation: locations.filter(function (_ref2) {
          var id = _ref2.id;
          return id === locationId;
        }).firstObject,
        reportTypes: reportTypes,
        subscriptions: subscriptions
      });
    }
  });

  _exports.default = _default;
});