define("lh4/components/ui/tree/report-node", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NODE_TYPES = {
    HEADER: 'header',
    TITLE: 'title',
    TOTAL: 'total',
    BOTTOM: 'bottom',
    ABOVE_HEADER: 'above-header'
  };

  var _default = Ember.Component.extend({
    tagName: '',
    location: Ember.inject.service(),
    isLoading: false,
    router: Ember.inject.service(),
    shouldShowHeader: Ember.computed('node.rows.[]', 'location.canViewLBM2', 'showCustomNodeTypes', function shouldShowHeader() {
      var firstRow = this.node.rows[0];
      var type = firstRow ? firstRow.type : null;
      var isCustomType = [NODE_TYPES.TOTAL, NODE_TYPES.BOTTOM, NODE_TYPES.ABOVE_HEADER].includes(type);
      var supportsCustomType = this.location.canViewLBM2 || this.showCustomNodeTypes;
      return type === NODE_TYPES.HEADER || type === NODE_TYPES.TITLE || isCustomType && supportsCustomType;
    }),
    actions: {
      toggleIsExpanded: function toggleIsExpanded() {
        // action is called recursively, so always take only first callers arguments
        // to bubble only single arguments object to parent components action
        // seems like all options to prevent event bubbling is not working with recursive components
        if (this.onToggleIsExpanded) {
          for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
          }

          this.onToggleIsExpanded([].concat(args).pop());
        }
      }
    }
  });

  _exports.default = _default;
});